/* Navbar */
.nbCustom {
  transition: background-color 0.3s ease;
}

.nbCustom.scrolled {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nbCustom.dark-theme {
  background-color: #333333;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.nbCustom.initial {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* Avatares */
.avatar-border {
  border: 1px solid #757575;
  padding: 4px;
  border-radius: 50%;
  display: inline-block;
}

/* Banner landing */
.bnLanding {
  position: relative;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}

.bnContainer {
  z-index: 1;
}

.bnBackContent {
  position: relative;
  z-index: 2;
}

.bnBackImage {
  height: 155%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.85;
}

/* Banner Md Landing */
.bnMdLanding {
  position: relative;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}

.bnMdContainer {
  z-index: 1;
}

.bnMdBackContent {
  position: relative;
  z-index: 2;
}

.bnMdBackImage {
  height: 155%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}

.fixedElement {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 22.5vh;
  z-index: 1;
  overflow: hidden;
}

/* Normal Landing */
.nmLanding {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow: hidden;
}

.nmContainer {
  z-index: 1;
}

.nmBackContent {
  position: relative;
  z-index: 2;
}

.nmBackImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
}

/* Contenido Lateral Derecho */
.sidebarTitles {
  padding-top: 270px;
  margin-top: -270px;
}

.cursorPointer {
  cursor: pointer;
}





/* Estilos generales del "loading" */
.loading-init {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  perspective: 1000px;  /* Añadir perspectiva */
}

/* Estilos del spinner o animación de carga */
.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f1c40f; /* Asegúrate de que el color de borde superior sea amarillo */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transform: translate3d(0, 0, 0); /* Forzar nueva capa de composición */
  animation: spin 2s linear infinite;
}

/* Definición de la animación */
@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top-color: #f1c40f; /* Amarillo */
  }
  33% {
    border-top-color: #3498db; /* Azul */
  }
  66% {
    border-top-color: #ff5733; /* Rojo */
  }
  100% {
    transform: rotate(360deg);
    border-top-color: #f1c40f; /* Amarillo */
  }
}




/* Estilos para los input */
.custom-input {
  text-align: center;
  /* Estilos adicionales para los input si es necesario */
}

/* Estilos para los placeholders de los input */
.custom-input::placeholder {
  text-align: center;
  /* Estilos adicionales para los placeholders si es necesario */
}





/* Estilos para el footer */
.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}





.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}
